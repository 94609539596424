import { ArrowLeft, ArrowRight, Person } from "@mui/icons-material";

import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  AVERAGE_COLOR,
  TARGET_JUDGE_COLOR,
  FC_JUDGE_COLOR,
} from "../../../util/constants";
import { $Member, JudgeEntry } from "../../../util/interfaces";
import { Action, Detail, Subtitle } from "../../../shared/Common";

const DOT_SIZE = 8;
const JUDGE_DOT_SIZE = 12;
const AVG_LINE_SIZE = 12;
const LABEL_SIZE = 12;
const SLIDE_OFFSET = 2;

const Layer = styled.div`
  position: relative;
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  padding: 64px;
  width: 100%;
  align-items: center;

  @media (max-width: 700px) {
    padding: 32px;
  }
`;

const GraphContainer = styled.div`
  margin-top: 48px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 700px) {
    width: 85%;
  }
`;

const LeftMarker = styled.div`
  height: 32px;
  width: 1px;
  background-color: black;
  transform: translateY(16px);

  p {
    margin: 0;
    position: absolute;
    transition: transform 350ms ease;
    bottom: 8px;
    left: -36px;
  }
`;

const RightMarker = styled.div`
  height: 32px;
  width: 1px;
  background-color: black;
  transform: translateY(16px);

  p {
    margin: 0;
    position: absolute;
    transition: transform 350ms ease;
    bottom: 8px;
    right: -48px;
  }
`;

const DataContainer = styled.div`
  height: 64px;
  border-bottom: solid 1px var(--grey-1);
  width: 100%;
  position: relative;
`;

const MemberDot = styled.div`
  height: ${DOT_SIZE}px;
  width: ${DOT_SIZE}px;
  background-color: ${FC_JUDGE_COLOR};
  border-radius: 100%;
  position: absolute;
  transition: transform 350ms ease;
  bottom: -4px;
`;

const TargetMemberDot = styled.div`
  height: ${JUDGE_DOT_SIZE}px;
  width: ${JUDGE_DOT_SIZE}px;
  background-color: ${TARGET_JUDGE_COLOR};
  opacity: 1;
  position: absolute;
  transition: transform 350ms ease;
  border-radius: 100%;
  bottom: -${JUDGE_DOT_SIZE / 2}px;
  display: flex;
  align-items: flex-start;
`;

const AvgDot = styled.div`
  height: ${JUDGE_DOT_SIZE}px;
  width: ${JUDGE_DOT_SIZE}px;
  background-color: ${AVERAGE_COLOR};
  opacity: 1;
  position: absolute;
  transition: transform 350ms ease;
  border-radius: 100%;
  bottom: -${AVG_LINE_SIZE / 2}px;
  display: flex;
  align-items: flex-start;
`;

const LabelsContainer = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: auto auto auto;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LabelColor = styled.div`
  margin-right: 4px;
  height: ${LABEL_SIZE}px;
  width: ${LABEL_SIZE}px;
  border-radius: 100%;
`;

const StatLabel = styled.div`
  padding: 4px 8px;
  height: max-content;
  width: max-content;
  background-color: var(--light-1);

  p {
    margin: 0;
  }
`;

const BlueLabel = styled.div`
  position: absolute;
  left: 24px;
  top: 60px;
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    top: -12px;
    left: calc(50% - 84px);
  }
`;

const BlueLabelBox = styled.div`
  width: 84px;
`;

const DotsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DotsGradient = styled.div`
  width: 100%;
  height: 8px;
  background: linear-gradient(0.25turn, #1da5a524, #1da5a5df);
`;

const SlideBox = styled.div`
  display: grid;
  margin-top: 124px;
  grid-template-columns: repeat(7, auto);
  grid-gap: 32px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

function FCMeritsHorizontalGraph({
  judges,
  judgeRate,
  judgeName,
  average,
}: {
  judges: JudgeEntry[];
  judgeRate?: number;
  judgeName?: string;
  average: number;
}) {
  const dotsContainer = useRef<HTMLDivElement>(null);
  const [dotsWidth, setDotsWidth] = useState(0);
  const [rates, setRates] = useState<number[]>([]);
  const [judgeList, setJudgeList] = useState<JudgeEntry[]>([]);
  const [judgeIndex, setJudgeIndex] = useState(0);
  const [offSet, setOffset] = useState(0);
  const [neighborJudges, setNeighborJudges] = useState<JudgeEntry[]>([]);

  const calculateOffset = (percent: number, width: number) => {
    return (dotsWidth * percent) / 100 - width / 2;
  };

  useEffect(() => {
    let list = judges.sort((a, b) => a.merits_rate ?? 0 - b.merits_rate ?? 0);

    const sortedRates = list.map(({ merits_rate }) => merits_rate ?? 0);
    const targetRate = judgeRate ?? average;

    setRates(sortedRates);

    let insertPos = 0;
    for (let i in sortedRates) {
      if (sortedRates[i] > targetRate!) {
        insertPos = parseInt(i);
        setJudgeIndex(parseInt(i));
        break;
      }
    }

    const center: JudgeEntry = {
      leave_rate: 0,
      id: "",
      jr_rate: 0,
      jr_total: 0,
      leave_total: 0,
      merits_rate: 0,
      merits_total: 0,
      name: judgeName || "Average",
    };

    list.splice(insertPos, 0, center);

    setJudgeList([...list]);
  }, [judges, average, judgeName, judgeRate]);

  useEffect(() => {
    const neighbors = judgeList.slice(
      judgeIndex - SLIDE_OFFSET + offSet,
      judgeIndex + SLIDE_OFFSET + 1 + offSet
    );

    const neighborsList = neighbors;

    setNeighborJudges(neighborsList);
  }, [judgeIndex, judgeList, judgeName, offSet]);

  useEffect(() => {
    if (dotsContainer.current) {
      setDotsWidth(dotsContainer.current.offsetWidth);
    }
  }, []);

  return (
    <Layer>
      <LabelsContainer>
        {judgeName && (
          <LabelContainer>
            <LabelColor style={{ backgroundColor: TARGET_JUDGE_COLOR }} />
            <Subtitle>{judgeName || "Unnamed Judge"}</Subtitle>
          </LabelContainer>
        )}
        <LabelContainer>
          <LabelColor style={{ backgroundColor: "#1da5a5" }} />
          <Subtitle>FC Judges</Subtitle>
        </LabelContainer>
        <LabelContainer>
          <LabelColor style={{ backgroundColor: AVERAGE_COLOR }} />
          <Subtitle>FC Average</Subtitle>
        </LabelContainer>
      </LabelsContainer>
      <GraphContainer>
        <LeftMarker>
          <p>0%</p>
        </LeftMarker>
        <DataContainer ref={dotsContainer}>
          {rates.map((percent, index) => (
            <MemberDot
              key={`dot-${index}`}
              style={{
                transform: `translateX(${calculateOffset(
                  percent ?? 0,
                  DOT_SIZE
                )}px)`,
              }}
            />
          ))}
          <AvgDot
            style={{
              transform: `translateX(${calculateOffset(
                average,
                AVG_LINE_SIZE
              )}px)`,
            }}
          >
            <StatLabel
              style={
                average > 50
                  ? {
                      transform: `translate(calc(-100% + ${
                        AVG_LINE_SIZE / 2 + 1
                      }px), 24px)`,
                      borderRight: `solid 1px ${AVERAGE_COLOR}`,
                    }
                  : {
                      transform: `translate(${AVG_LINE_SIZE / 2 - 1}px, 24px)`,
                      borderLeft: `solid 1px ${AVERAGE_COLOR}`,
                    }
              }
            >
              <p>{average}%</p>
            </StatLabel>
          </AvgDot>
          {judgeRate !== undefined && (
            <TargetMemberDot
              style={{
                transform: `translateX(${calculateOffset(
                  judgeRate ?? 0,
                  JUDGE_DOT_SIZE
                )}px)`,
              }}
            >
              <StatLabel
                style={
                  judgeRate > 50
                    ? {
                        transform: `translate(calc(-100% + ${
                          JUDGE_DOT_SIZE / 2
                        }px), -40px)`,
                        borderRight: `solid 1px ${TARGET_JUDGE_COLOR}`,
                      }
                    : {
                        transform: `translate(${JUDGE_DOT_SIZE / 2}px, -40px)`,
                        borderLeft: `solid 1px ${TARGET_JUDGE_COLOR}`,
                      }
                }
              >
                <p>{judgeRate ?? 0}%</p>
              </StatLabel>
            </TargetMemberDot>
          )}
        </DataContainer>
        <RightMarker>
          <p>100%</p>
        </RightMarker>
      </GraphContainer>
      <BlueLabel>
        <Person style={{ marginRight: "12px" }} />
        <BlueLabelBox>
          <DotsBox>
            <Detail>1</Detail>
            <Detail>5</Detail>
            <Detail style={{ marginRight: "-16px" }}>10+</Detail>
          </DotsBox>
          <DotsGradient />
        </BlueLabelBox>
      </BlueLabel>

    </Layer>
  );
}

export default FCMeritsHorizontalGraph;
