import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  parsePercent,
  processIRBMembers,
  processIRBMembersData,
  processRecognitionRatesPerYear,
  reformatIRBData,
  reformatIRBEntries,
  reformatIRBYearEntry,
  reformatIRBYearsData,
  sortCountries,
  sortMembers,
} from "../../util/helpers";
import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import CountUp from "react-countup";
import {
  Action,
  Actions,
  CaseCountToolTip,
  CountryListOptions,
  Detail,
  Display,
  FilterBox,
  Message,
  NoData,
  RecognitionRateToolTip,
  Title,
  Section,
  SectionLabel,
  SectionLabelsContainer,
  Stat,
  SubSections,
  Subtitle,
  PageFilterBox,
} from "../../shared/Common";
import Members from "../Members";
import {
  $Member,
  CountrySortOption,
  IRBCountry,
  IRBDataEntry,
  IRBQueryResponseEntry,
  MemberSortOption,
  RecognitionRatePerYearFormat,
} from "../../util/interfaces";
import {
  Clear,
  ErrorOutline,
  Fullscreen,
  FullscreenExit,
  Refresh,
  Search,
  Tune,
} from "@mui/icons-material";
import SectionIndicator from "../SectionIndicator";
import {
  ALL_INDICATOR,
  IRB_AVAILABLE_YEARS,
  IRB_EARLIEST_YEAR,
  IRB_LATEST_YEAR,
} from "../../util/constants";
import HorizontalGraph from "../graphs/HorizontalGraph";
import RecognitionRatePerYear from "../graphs/DecisionMaker/IRB/RecognitionRatePerYear";
import ActualRecognitionRatePerPredictedRecognitionRate from "../graphs/DecisionMaker/IRB/ActualRecognitionRatePerPredictedRecognitionRate";
import Countries from "../Countries";
import { useDb } from "../../services/UseDb";
import {
  getRpdCountries,
  getRpdMemberList,
  getRpdMembers,
  getRpdYear,
  getRpdYears,
} from "../../services/db";


const Layer = styled.div`
  display: flex;
`;

const MembersOptions = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 3fr;
  grid-column-gap: 16px;
  margin: 16px 0px;
  height: 40px;

  input,
  label {
    font-family: DM Sans;
  }

  div {
    height: 40px;
  }

  @media (max-width: 700px) {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    height: min-content;

    div:nth-of-type(1) {
      grid-column: span 3;
    }

    div:nth-of-type(2) {
      grid-column: auto / span 1;
    }

    div:nth-of-type(3) {
      grid-column: auto / span 2;
    }
  }
`;


const KeyStatsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 16px 0;

  h2,
  label,
  p {
    color: var(--light-1);
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 700px) {
    grid-template-columns: none;
    grid-template-rows: repeat(auto);
    height: max-content;
  }
`;

const StatBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 260px;

  @media (max-width: 700px) {
    height: 148px;
  }
`;

const StatBoxTop = styled.div`
  padding: 16px;
  background-color: var(--dark-1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > p {
    text-align: end;
  }
`;

const StatBoxTopHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatBoxBottom = styled.div`
  padding: 16px;
  background-color: var(--dark-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
`;

function ImmigrationRefugeeBoard({
  duckDbConn,
}: {
  duckDbConn: AsyncDuckDBConnection;
}) {
  const [members, setMembers] = useState<$Member[]>([]);
  const [loading, setLoading] = useState(true);

  const [allMembersRequest, setAllMembersRequest] = useState<
    IRBQueryResponseEntry[]
  >([]);
  const [membersListRequest, setMembersListRequest] = useState<
    IRBQueryResponseEntry[]
  >([]);
  const [yearsRequest, setYearsRequest] = useState<IRBDataEntry[]>([]);

  // List of members shown
  // data
  const [memberList, setMemberList] = useState<$Member[]>([]);
  // summary information (name, last date, cases)
  const [membersOverview, setMembersOverview] = useState<$Member[]>([]);

  // Sorted members
  const [sortedMembers, setSortedMembers] = useState<$Member[]>([]);
  const [memberSortOption, setMemberSortOptions] =
    useState<MemberSortOption>("last");

  // Filtered members
  const [filteredMembers, setFilteredMembers] = useState<$Member[]>([]);
  const [memberSearchFilter, setMemberSearchFilter] = useState("");

  // Country list etc
  const [countries, setCountries] = useState<IRBCountry[]>([]);
  const [countryList, setCountryList] = useState<IRBCountry[]>([]);

  const [filteredCountries, setFilteredCountries] = useState<IRBCountry[]>([]);
  const [countrySearchFilter, setCountrySearchFilter] = useState("");

  const [sortedCountries, setSortedCountries] = useState<IRBCountry[]>([]);
  const [countrySortOption, setCountrySortOption] =
    useState<CountrySortOption>("claims");


  // Graph Full screens
  const [
    actualRecognitionRatePerPredictedRecognitionRateFullScreen,
    setActualRecognitionRatePerPredictedRecognitionRateFullScreen,
  ] = useState(false);
  const [activeFullScreenGraph, setActiveFullScreenGraph] = useState(false);

  // Graph filtering display
  const [showPageFilter, setShowPageFilter] = useState(true);

  // Data
  const [recognitionRate, setRecognitionRate] = useState(0);
  const [averageRecognitionRate, setAverageRecognitionRate] = useState(0);
  // const [countryRecognitionRate, setCountryRecognitionRate] = useState(0);
  // const [averageCountryRecognitionRate, setAverageCountryRecognitionRate] =
  //   useState(0);
  const [decisionsNum, setDecisionsNum] = useState(0);
  const [decisionsNumAverage, setDecisionsNumAverage] = useState(0);
  const [activeMembersNum, setActiveMembersNum] = useState(0);
  const [memberRates, setMemberRates] = useState<
    { rate: number; predicted: number }[]
  >([]);
  const [recognitionRatePerYearData, setRecognitionRatePerYearData] = useState<
    RecognitionRatePerYearFormat[]
  >([]);

  // Filters for page
  const [countryPageFilter, setCountryPageFilter] = useState(ALL_INDICATOR);
  const [yearPageFilter, setYearPageFilter] = useState(ALL_INDICATOR);


  const { loading: countriesLoading, data: countriesData } = useDb(
    getRpdCountries(
      duckDbConn,
      ALL_INDICATOR,
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter)
    ),
    [yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter)]
  );

  const { loading: countriesAllYearsLoading, data: countriesAllYearsData } =
    useDb(getRpdCountries(duckDbConn, ALL_INDICATOR, null));


  const { loading: yearAvgLoading, data: yearAvgData } = useDb(
    getRpdYear(duckDbConn, countryPageFilter, ALL_INDICATOR),
    [countryPageFilter]
  );


  const { loading: yearCountryLoading, data: yearCountryData } = useDb(
    getRpdYear(
      duckDbConn,
      countryPageFilter,
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter)
    ),
    [
      countryPageFilter,
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter),
    ]
  );


  const { loading: membersDataLoading, data: membersData } = useDb(
    getRpdMembers(
      duckDbConn,
      countryPageFilter,
      20,
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter)
    ),
    [
      countryPageFilter,
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter),
    ]
  );


  const { loading: membersListLoading, data: membersListData } = useDb(
    getRpdMemberList(
      duckDbConn,
      countryPageFilter,
      20,
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter)
    ),
    [
      countryPageFilter,
      yearPageFilter === ALL_INDICATOR ? null : parseInt(yearPageFilter),
    ]
  );


  const { loading: yearsLoading, data: yearsData } = useDb(
    getRpdYears(duckDbConn, countryPageFilter, "ASC"),
    [countryPageFilter]
  );
  // end of queries

  useEffect(() => {
    const fullScreenAcive =
      actualRecognitionRatePerPredictedRecognitionRateFullScreen;

    setActiveFullScreenGraph(fullScreenAcive);
  }, [actualRecognitionRatePerPredictedRecognitionRateFullScreen]);

  useEffect(() => {
    if (
      countriesData &&
      yearCountryData &&
      yearAvgData &&
      countriesAllYearsData
    ) {
      const avgYearOverview = reformatIRBYearEntry(yearAvgData);
      const selectedYearCountryOverview = reformatIRBYearEntry(yearCountryData);

      const countriesAllYears = new Map();

      for (const { count_min, country_id } of countriesAllYearsData) {
        countriesAllYears.set(country_id, count_min);
      }

      const countryEntries: IRBCountry[] = countriesData.map(
        ({
          country_id,
          count_min,
          recognition_rate_actual,
        }: {
          country_id: string;
          count_min: number;
          recognition_rate_actual: number;
        }) => ({
          name: country_id,
          rate: parsePercent(recognition_rate_actual),
          decisions: count_min,
          avgDecisions: Math.floor(
            countriesAllYears.get(country_id) / IRB_AVAILABLE_YEARS.length
          ),
        })
      );

      setRecognitionRate(selectedYearCountryOverview.year.recognition_rate);
      setAverageRecognitionRate(avgYearOverview.year.recognition_rate);
      setDecisionsNum(selectedYearCountryOverview.year.decisions);
      setDecisionsNumAverage(avgYearOverview.year.decisions);
      setCountries(countryEntries);
    }
  }, [
    countriesData,
    yearCountryData,
    yearAvgData,
    yearPageFilter,
    countriesAllYearsData,
  ]);

  useEffect(() => {
    if (membersData && yearsData && membersListData) {
      setAllMembersRequest(membersData);
      setMembersListRequest(membersListData);
      setYearsRequest(reformatIRBYearsData(yearsData));
    }
  }, [membersData, yearsData, membersListData]);

  // Set data for all members
  useEffect(() => {
    const allMembers = processIRBMembersData(
      reformatIRBEntries(allMembersRequest)
    );
    const allMembersRatePredicted = allMembers.map(
      ({ rate, predicted, memberId, firstName, lastName }) => ({
        rate: rate!,
        predicted: predicted!,
        id: memberId,
        name: `${firstName} ${lastName}`,
      })
    );
    setMemberRates(allMembersRatePredicted);
    setMembers(allMembers);
    setActiveMembersNum(allMembers.length);
  }, [allMembersRequest]);

  useEffect(() => {
    const irbData = reformatIRBData(membersListRequest);
    const currMembers = processIRBMembers(irbData);
    setMembersOverview(currMembers);
  }, [membersListRequest]);

  useEffect(() => {
    setRecognitionRatePerYearData(processRecognitionRatesPerYear(yearsRequest));
  }, [yearsRequest]);

  useEffect(() => {
    let searchResults = [...membersOverview];
    searchResults = membersOverview.filter(
      ({ firstName, lastName }) =>
        firstName.toUpperCase().includes(memberSearchFilter.toUpperCase()) ||
        lastName.toUpperCase().includes(memberSearchFilter.toUpperCase())
    );
    setFilteredMembers(searchResults);
  }, [memberSearchFilter, membersOverview]);

  useEffect(() => {
    setMemberList(memberSearchFilter ? filteredMembers : sortedMembers);
  }, [sortedMembers, filteredMembers, memberSearchFilter]);

  useEffect(() => {
    const sortedMembers = sortMembers(membersOverview, memberSortOption);
    setSortedMembers(sortedMembers);
  }, [memberSortOption, membersOverview]);

  // Country list logic for sorting and filtering
  useEffect(() => {
    const sortedCountries = sortCountries([...countries], countrySortOption);
    setSortedCountries(sortedCountries);
  }, [countrySortOption, countries]);

  useEffect(() => {
    let searchResults = [...countries];
    searchResults = countries.filter(({ name }) =>
      name.toUpperCase().includes(countrySearchFilter.toUpperCase())
    );
    setFilteredCountries(searchResults);
  }, [countrySearchFilter, countries]);

  useEffect(() => {
    setCountryList(countrySearchFilter ? filteredCountries : sortedCountries);
  }, [sortedCountries, filteredCountries, countrySearchFilter]);

  useEffect(() => {
    setLoading(
      yearsLoading ||
        membersDataLoading ||
        yearsLoading ||
        countriesLoading ||
        yearCountryLoading ||
        yearAvgLoading ||
        membersListLoading ||
        countriesAllYearsLoading
    );
  }, [
    yearsLoading,
    countriesLoading,
    membersDataLoading,
    yearCountryLoading,
    yearAvgLoading,
    membersListLoading,
    countriesAllYearsLoading,
  ]);

  return (
    <Layer>
      <SectionLabelsContainer>
        <Detail>Immigration Refugee Board</Detail>
        <SectionIndicator start="about" />
        <SectionLabel href="#about" indented label="About" />
        <SectionLabel href="#key-data" indented label="Key Data" />
        <SubSections>
          <Detail>Recognition Rate</Detail>
          <Detail>Number of Decisions</Detail>
          <Detail>Number of Active Members</Detail>
          <Detail>Recognition Rates per Year</Detail>
        </SubSections>
        <SectionLabel href="#member-data" indented label="Member Data" />
        <SubSections>
          <Detail>Recognition Rates per Member</Detail>
          <Detail>Predicted Recognition Rates</Detail>
        </SubSections>
        <SectionLabel
          href="#countries-of-origin"
          indented
          label="Countries of Origin"
        />
        <SectionLabel href="#members" indented label="Members" />
      </SectionLabelsContainer>
      <Container>
        <Section id="about">
          <Display>Immigration Refugee Board</Display>
          <p>
            The <a href="https://irb.gc.ca/">Immigration and Refugee Board</a>{" "}
            is Canada's largest quasi-judicial administrative tribunal. The
            IRB's Refugee Protection Division is responsible for adjudicating
            refugee claims made in Canada. Decisions are made by adjudicators
            who are known as Board Members.{" "}
            <a href="https://refugeelab.ca/projects/refugee-law-data/">Data</a>{" "}
            obtained by the Refugee Law Lab from the IRB through Access to
            Information Requests and a data sharing agreement reveals vast
            disparities in refugee claim recognition rates across Board Members.
            For information about methodology, see these articles from{" "}
            <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=1468717">
              2008
            </a>{" "}
            and{" "}
            <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4341740">
              2023
            </a>
            .
          </p>
          <p>
            Some of the recognition rate variation observed in the data is due
            to specialization in particular types of cases. For example, some
            decision-makers specialize in geographic regions with especially
            high or low refugee claim recognition rates. It should also be kept
            in mind that to enhance efficiency the RPD has recently placed
            increased emphasis on streaming cases into different categories,
            including expedited cases that are granted based on paper reviews
            rather than hearings. The proportion of such claims heard by
            particular decision-makers may affect their recognition rates. For
            further possible explanations for variations in recognition rates,
            please see an explanatory{" "}
            <a href="https://refugeelab.ca/wp-content/uploads/2025/03/2025-03-Eng-Explanatory-Note-on-refugee-acceptance-rates.pdf">
              note
            </a>{" "}
            (also available in{" "}
            <a href="https://refugeelab.ca/wp-content/uploads/2025/03/2025-03-Fr-Explanatory-Note-on-refugee-acceptance-rates.pdf">
              French
            </a>
            ) that was provided by the IRB.
          </p>
          <Actions>
            <Button variant="contained" color="primary">
              <a href="#members">
                <Action>Search members</Action>
              </a>
            </Button>
            <Button
              style={{ marginLeft: "12px" }}
              variant="contained"
              color="primary"
            >
              <a href="#countries-of-origin">
                <Action>Search countries</Action>
              </a>
            </Button>
          </Actions>
        </Section>
        <hr />
        <div style={{ position: "relative" }}>
          {showPageFilter && (
            <PageFilterBox
              style={
                activeFullScreenGraph
                  ? {
                      boxShadow: "none",
                      borderLeft: "solid 1px var(--dark-2)",
                      borderBottom: "solid 1px var(--dark-2)",
                      borderRadius: "0px",
                      position: "fixed",
                      right: "32px",
                      top: "64px",
                    }
                  : {}
              }
            >
              <Detail style={{ marginRight: "12px" }}>Filter by</Detail>
              <FilterBox>
                <Detail>year</Detail>
                <Select
                  size="small"
                  onChange={(e) => setYearPageFilter(e.target.value)}
                  value={yearPageFilter}
                >
                  <MenuItem value={ALL_INDICATOR}>
                    <Action>
                      All ({IRB_EARLIEST_YEAR} to {IRB_LATEST_YEAR})
                    </Action>
                  </MenuItem>
                  {IRB_AVAILABLE_YEARS.sort()
                    .reverse()
                    .map((year) => (
                      <MenuItem key={`y-${year}`} value={year}>
                        <Action>{year}</Action>
                      </MenuItem>
                    ))}
                </Select>
              </FilterBox>
              <FilterBox style={{ marginLeft: "16px" }}>
                <Detail>country</Detail>
                <Select
                  size="small"
                  onChange={(e) => setCountryPageFilter(e.target.value)}
                  value={countryPageFilter}
                >
                  <MenuItem value={ALL_INDICATOR}>
                    <Action>All</Action>
                  </MenuItem>
                  {countries.map(({ name }) => (
                    <MenuItem key={`item-${name}`} value={name}>
                      <Action>{name}</Action>
                    </MenuItem>
                  ))}
                </Select>
                <IconButton
                  onClick={() => {
                    setYearPageFilter(ALL_INDICATOR);
                    setCountryPageFilter(ALL_INDICATOR);
                  }}
                  disabled={
                    yearPageFilter === "+ALL" && countryPageFilter === "+ALL"
                  }
                  style={{ marginLeft: "8px" }}
                >
                  <Refresh fontSize="small" />
                </IconButton>
              </FilterBox>
              {!activeFullScreenGraph && (
                <Button onClick={() => setShowPageFilter(false)}>
                  <Action>hide</Action>
                </Button>
              )}
            </PageFilterBox>
          )}
          {!showPageFilter && !activeFullScreenGraph && (
            <PageFilterBox
              style={{
                padding: "8px",
              }}
            >
              <Button onClick={() => setShowPageFilter(true)}>
                <Action style={{ marginRight: "8px" }}>
                  Filter by year and country
                </Action>
                <Tune fontSize="small" />
              </Button>
            </PageFilterBox>
          )}
          <Section id="key-data">
            <Display>Key Data</Display>
            <Title>
              from{" "}
              {yearPageFilter === ALL_INDICATOR ? (
                <>
                  <b>{IRB_EARLIEST_YEAR}</b> to <b>{IRB_LATEST_YEAR}</b>
                </>
              ) : (
                <b>{yearPageFilter}</b>
              )}{" "}
              for{" "}
              <b>
                {countryPageFilter === ALL_INDICATOR
                  ? "all countries"
                  : countryPageFilter}
              </b>
            </Title>
            <KeyStatsContainer>
              <StatBox>
                <StatBoxTop style={{ backgroundColor: "#255A78" }}>
                  <StatBoxTopHeader>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h2>Recognition Rate</h2>
                      <RecognitionRateToolTip color="light" />
                    </div>
                  </StatBoxTopHeader>
                  <Stat>
                    <CountUp
                      preserveValue
                      decimals={1}
                      end={recognitionRate}
                      duration={0.25}
                    />
                    %
                  </Stat>
                </StatBoxTop>
                {yearPageFilter !== ALL_INDICATOR && (
                  <StatBoxBottom>
                    <Detail>
                      total ({IRB_EARLIEST_YEAR} to {IRB_LATEST_YEAR})
                    </Detail>
                    <h2>
                      <CountUp
                        preserveValue
                        decimals={1}
                        end={averageRecognitionRate}
                        duration={0.25}
                      />
                      %
                    </h2>
                  </StatBoxBottom>
                )}
              </StatBox>
              <StatBox>
                <StatBoxTop style={{ backgroundColor: "#645c37" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h2>Decisions</h2>
                    <CaseCountToolTip color="light" />
                  </div>
                  <Stat>
                    ~
                    <CountUp
                      preserveValue
                      end={decisionsNum}
                      formattingFn={(n) => n.toLocaleString()}
                      duration={0.25}
                    />
                  </Stat>
                </StatBoxTop>
                {yearPageFilter !== ALL_INDICATOR && (
                  <StatBoxBottom>
                    <Detail>
                      total ({IRB_EARLIEST_YEAR} to {IRB_LATEST_YEAR})
                    </Detail>
                    <h2>
                      <CountUp
                        preserveValue
                        decimals={1}
                        end={decisionsNumAverage}
                        formattingFn={(n) => n.toLocaleString()}
                        duration={0.25}
                      />{" "}
                    </h2>
                  </StatBoxBottom>
                )}
              </StatBox>
              <StatBox>
                <StatBoxTop style={{ backgroundColor: "#AC346D" }}>
                  <StatBoxTopHeader>
                    <h2>Active Members</h2>
                    <Subtitle>with 20+ decisions</Subtitle>
                  </StatBoxTopHeader>
                  <Stat>
                    <CountUp
                      preserveValue
                      end={activeMembersNum}
                      duration={0.25}
                    />
                  </Stat>
                </StatBoxTop>
              </StatBox>
            </KeyStatsContainer>
          </Section>
          <Section id="member-data">
            <Display>Member Data</Display>
            <Title>
              from{" "}
              {yearPageFilter === ALL_INDICATOR ? (
                <>
                  <b>{IRB_EARLIEST_YEAR}</b> to <b>{IRB_LATEST_YEAR}</b>
                </>
              ) : (
                <b>{yearPageFilter}</b>
              )}{" "}
              for{" "}
              <b>
                {countryPageFilter === ALL_INDICATOR
                  ? "all countries"
                  : countryPageFilter}
              </b>
            </Title>
            <div>
              <h2>Recognition Rates by Member</h2>
              {!memberRates.length ? (
                <NoData />
              ) : (
                <HorizontalGraph
                  average={recognitionRate}
                  members={[...members]}
                />
              )}
            </div>
            <div
              style={
                actualRecognitionRatePerPredictedRecognitionRateFullScreen
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "var(--light-1)",
                      height: "100vh",
                      width: "100vw",
                      position: "fixed",
                      top: 0,
                      left: 0,
                      zIndex: 20,
                      padding: "24px",
                    }
                  : {}
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2>Actual Recognition Rate per Predicted Recognition Rate</h2>
                <div>
                  {activeFullScreenGraph && (
                    <Button onClick={() => setShowPageFilter(!showPageFilter)}>
                      <Action>{showPageFilter ? "hide" : "show"} filter</Action>
                    </Button>
                  )}
                  <IconButton
                    onClick={() =>
                      setActualRecognitionRatePerPredictedRecognitionRateFullScreen(
                        !actualRecognitionRatePerPredictedRecognitionRateFullScreen
                      )
                    }
                  >
                    {actualRecognitionRatePerPredictedRecognitionRateFullScreen ? (
                      <FullscreenExit />
                    ) : (
                      <Fullscreen />
                    )}
                  </IconButton>
                </div>
              </div>
              {!memberRates.length ? (
                <NoData />
              ) : (
                <ActualRecognitionRatePerPredictedRecognitionRate
                  fullScreen={
                    actualRecognitionRatePerPredictedRecognitionRateFullScreen
                  }
                  data={[
                    ...memberRates,
                    {
                      predicted: recognitionRate,
                      average: recognitionRate,
                    },
                  ]}
                  showAverage
                />
              )}
            </div>
            <div>
              <h2 style={{ marginBottom: "4px" }}>
                Recognition Rates per Year
              </h2>
              <Title>
                for{" "}
                {
                  <b>
                    {countryPageFilter === ALL_INDICATOR
                      ? "all countries"
                      : countryPageFilter}
                  </b>
                }{" "}
                from {IRB_EARLIEST_YEAR} to {IRB_LATEST_YEAR}
              </Title>
              {recognitionRatePerYearData.length > 2 ? (
                <RecognitionRatePerYear
                  showPredicted={false}
                  selectedYear={yearPageFilter}
                  data={recognitionRatePerYearData.map(({ date, rate }) => ({
                    date,
                    rate,
                  }))}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "48px 0",
                  }}
                >
                  <ErrorOutline />
                  <Subtitle style={{ marginLeft: "8px" }}>
                    Not enough data to display
                  </Subtitle>
                </div>
              )}
            </div>
          </Section>
          <hr />
          <Section id="countries-of-origin">
            <Display>Countries of Origin</Display>
            <Title>
              with 20+ decisions from{" "}
              {yearPageFilter === ALL_INDICATOR ? (
                <>
                  <b>{IRB_EARLIEST_YEAR}</b> to <b>{IRB_LATEST_YEAR}</b>
                </>
              ) : (
                <b>{yearPageFilter}</b>
              )}
            </Title>
            {countryPageFilter === ALL_INDICATOR && (
              <CountryListOptions>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Search by name"
                  style={{ width: "100%" }}
                  value={countrySearchFilter}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setCountrySearchFilter(event.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {countrySearchFilter !== "" ? (
                          <IconButton
                            size="small"
                            aria-label="search"
                            onClick={() => setCountrySearchFilter("")}
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        ) : (
                          <Search fontSize="small" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Message
                  text={`${countryList.length} ${
                    countryList.length === 1 ? "result" : "results"
                  }`}
                  type="subtitle"
                />
                <Actions>
                  <FilterBox>
                    <Detail>Sort by</Detail>
                    <Select
                      size="small"
                      onChange={(e) =>
                        setCountrySortOption(
                          e.target.value as CountrySortOption
                        )
                      }
                      value={countrySortOption}
                      disabled={countrySearchFilter !== ""}
                    >
                      <MenuItem value={"claims"}>
                        <Action>number of claims</Action>
                      </MenuItem>
                      <MenuItem value={"alphabetical"}>
                        <Action>alphabetical</Action>
                      </MenuItem>
                      <MenuItem value={"recognition"}>
                        <Action>recognition rate</Action>
                      </MenuItem>
                    </Select>
                  </FilterBox>
                </Actions>
              </CountryListOptions>
            )}
            <Countries
              setCountryFilter={setCountryPageFilter}
              countryFilter={countryPageFilter}
              countries={countryList}
            />
          </Section>
          <Section id="members">
            <Display>Members</Display>
            <Title>
              with 20+ decisions from{" "}
              {yearPageFilter === ALL_INDICATOR ? (
                <>
                  <b>{IRB_EARLIEST_YEAR}</b> to <b>{IRB_LATEST_YEAR}</b>
                </>
              ) : (
                <b>{yearPageFilter}</b>
              )}{" "}
              for{" "}
              <b>
                {countryPageFilter === ALL_INDICATOR
                  ? "all countries"
                  : countryPageFilter}
              </b>
            </Title>
            <MembersOptions>
              <TextField
                size="small"
                variant="outlined"
                label="Search by name"
                style={{ width: "100%" }}
                value={memberSearchFilter}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setMemberSearchFilter(event.target.value)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {memberSearchFilter !== "" ? (
                        <IconButton
                          size="small"
                          aria-label="search"
                          onClick={() => setMemberSearchFilter("")}
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      ) : (
                        <Search fontSize="small" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Message
                text={`${memberList.length} ${
                  memberList.length === 1 ? "result" : "results"
                }`}
                type="subtitle"
              />
              <Actions>
                <FilterBox>
                  <Detail>Sort by</Detail>
                  <Select
                    size="small"
                    onChange={(e) =>
                      setMemberSortOptions(e.target.value as MemberSortOption)
                    }
                    value={memberSortOption}
                  >
                    <MenuItem value="last">
                      <Action>Last name</Action>
                    </MenuItem>
                    <MenuItem value="first">
                      <Action>First name</Action>
                    </MenuItem>
                    <MenuItem value="cases">
                      <Action>Number of claims</Action>
                    </MenuItem>
                    <MenuItem value="rate">
                      <Action>Recognition rate</Action>
                    </MenuItem>
                    <MenuItem value="predicted">
                      <Action>Predicted recognition rate</Action>
                    </MenuItem>
                  </Select>
                </FilterBox>
              </Actions>
            </MembersOptions>
            <Members members={memberList} />
          </Section>
        </div>
      </Container>
    </Layer>
  );
}

export default ImmigrationRefugeeBoard;
