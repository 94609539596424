import {
  ALL_INDICATOR,
  FC_EARLIEST_YEAR,
  FC_LATEST_YEAR,
  IRB_AVAILABLE_YEARS,
  IRB_EARLIEST_YEAR,
  IRB_LATEST_YEAR,
} from "./constants";
import {
  $Member,
  ActualRecognitionRatePerPredictedRecognitionRateFormat,
  CasesPerYearFormat,
  CountrySortOption,
  DocketData,
  IRBCountry,
  IRBDataEntry,
  Judge,
  IRBQueryResponseEntry,
  MemberSortOption,
  MemberSummary,
  RecognitionRatePerYearFormat,
  RefugeeJRLeaveRatePerYearFormat,
} from "./interfaces";

export const calculateCasesPerYear = (
  judge: string,
  docketData: DocketData[]
): CasesPerYearFormat[] => {
  const casesPerYear: {
    [year: number]: number;
  } = {};

  for (const docket of docketData) {
    if (docket.leave_judge === judge) {
      const year = parseInt(docket.date_decision!.split("-")[0]);

      if (year in casesPerYear) {
        casesPerYear[year] += 1;
      } else {
        casesPerYear[year] = 1;
      }
    }
  }

  const result: CasesPerYearFormat[] = [];

  Object.entries(casesPerYear).forEach(([year, count]) => {
    result.push({
      year: parseInt(year),
      count,
    });
  });

  return result;
};

export const calculateRefugeeJRLeaveRatePerYear = (
  judge: string,
  docketData: DocketData[]
): RefugeeJRLeaveRatePerYearFormat[] => {
  const cases: {
    [year: number]: {
      fcTotal: number;
      fcLeave: number;
      total: number;
      jrLeave: number;
    };
  } = {};

  for (const docket of docketData) {
    const year = parseInt(docket.date_decision!.split("-")[0]);

    if (!cases[year]) {
      cases[year] = {
        fcTotal: 0,
        fcLeave: 0,
        total: 0,
        jrLeave: 0,
      };
    }

    cases[year].fcTotal += 1;

    if (docket.leave_judge === judge) {
      cases[year].total += 1;
      cases[year].jrLeave += docket.leave_yes ? 1 : 0;
    } else {
      cases[year].fcLeave += docket.leave_yes ? 1 : 0;
    }
  }

  const result: RefugeeJRLeaveRatePerYearFormat[] = [];

  Object.entries(cases)
    .filter(([_, { total, jrLeave }]) => total > 0) // Ensure no division by zero
    .forEach(([year, { total, jrLeave, fcTotal, fcLeave }]) => {
      result.push({
        year: parseInt(year),
        rate: total > 0 ? Math.round((jrLeave / total) * 100) : 0,
        fcAvg: fcTotal > 0 ? Math.round((fcLeave / fcTotal) * 100) : 0,
      });
    });

  return result;
};

export const processRPDName = (name: string) => {
  const [lastName, firstName] = name
    .toLowerCase()
    .split(", ")
    .map((word) => word[0].toUpperCase() + word.slice(1));

  return {
    memberId: name,
    lastName,
    firstName,
  };
};

export const parseBin = (bin: string) => parseInt(bin.split("-")[0]);

export const reformatIRBYearEntry = (entries: IRBQueryResponseEntry[]) => {
  if (entries && entries.length === 0) {
    return {
      average_per_year: {
        recognition_rate: 0,
        decisions: 0,
        members: 0,
      },
      year: {
        recognition_rate: 0,
        decisions: 0,
        members: 0,
      },
    };
  }

  const { recognition_rate_actual, recognition_rate_predicted, count_min } =
    entries
      ? entries[0]
      : {
          recognition_rate_actual: 0,
          recognition_rate_predicted: 0,
          count_min: 0,
        };

  return {
    average_per_year: {
      recognition_rate: parsePercent(recognition_rate_predicted),
      decisions: 0,
      members: 0,
    },
    year: {
      recognition_rate: parsePercent(recognition_rate_actual),
      decisions: count_min!, //parseInt(count_min!),
      members: 0,
    },
  };
};

export const reformatIRBData = (
  entries: IRBQueryResponseEntry[]
): MemberSummary[] =>
  entries.map(
    ({
      count_min,
      member_id,
      recognition_rate_actual,
      recognition_rate_predicted,
    }) => ({
      bin: count_min!,
      name: member_id!,
      latest_year: 0,
      rate: recognition_rate_actual,
      predicted: recognition_rate_predicted,
    })
  );

export const reformatIRBEntries = (
  entries: IRBQueryResponseEntry[]
): IRBDataEntry[] =>
  entries.map(
    ({
      recognition_rate_actual,
      recognition_rate_predicted,
      count_min,
      country_id,
      member_id,
      year,
    }) => ({
      bin: count_min!, //parseInt(count_min!),
      country: country_id!,
      predicted: recognition_rate_predicted,
      rate: recognition_rate_actual,
      date: year!,
      name: member_id!,
    })
  );

export const reformatIRBYearsData = (
  entries: IRBQueryResponseEntry[]
): IRBDataEntry[] =>
  entries.map(
    ({ recognition_rate_actual, recognition_rate_predicted, year }) => ({
      date: year!,
      predicted: recognition_rate_predicted!,
      rate: recognition_rate_actual!,
      bin: 0,
      country: "",
      name: "",
    })
  );

export const processIRBMembers = (
  members: MemberSummary[],
  memberName?: string
): $Member[] =>
  members
    .filter(({ name }) => {
      const isMember = memberName && name.includes(memberName);

      return (
        !name.includes("UNKNOWN") && !name.includes(ALL_INDICATOR) && !isMember
      );
    })
    .map(({ name, latest_year, bin, rate, predicted }): $Member => {
      const { lastName, firstName } = processRPDName(name);

      return {
        memberId: name,
        firstName,
        lastName,
        recentDate: latest_year.toString(),
        caseCount: bin, // parseBin(bin),
        rate: parsePercent(rate),
        predicted: parsePercent(predicted),
      };
    });

export const processIRBMembersData = (
  members: IRBDataEntry[],
  memberName?: string
): $Member[] =>
  members
    .filter(({ name }) => {
      const isMember = memberName && name!.includes(memberName);

      return (
        !name!.includes("UNKNOWN") &&
        !name!.includes(ALL_INDICATOR) &&
        !isMember
      );
    })
    .map(({ name, predicted, rate }): $Member => {
      const { lastName, firstName } = processRPDName(name!);

      return {
        memberId: name!,
        firstName,
        lastName,
        rate: parsePercent(rate),
        predicted: parsePercent(predicted),
      };
    });

export const processIRBInfo = (
  members: [
    {
      name: string;
      latest_year: number;
      bin: string;
      predicted: number;
      rate: number;
    }
  ]
) => {
  const { bin, rate, predicted } = members.find(
    ({ name }) => name === ALL_INDICATOR
  )!;

  return {
    caseCount: parseBin(bin),
    rate: Math.round(rate * 100),
    predicted: Math.round(predicted * 100),
  };
};

export const parsePercent = (p: number) => parseFloat((p * 100).toFixed(1));

export const processIRBMemberData = (
  data: IRBDataEntry[],
  dateFilter: number | String | null = ALL_INDICATOR
) => {
  dateFilter =
    dateFilter === ALL_INDICATOR ? null : parseInt(dateFilter as string);

  const countries: IRBCountry[] = [];
  let decisionsNum = 0;
  let recognitionRate = 0;
  let predictedRecognitionRate = 0;

  for (const entry of data) {
    const { bin, country, date, predicted, rate } = entry;

    if (country === ALL_INDICATOR && date === dateFilter) {
      decisionsNum = bin;
      recognitionRate = parsePercent(rate);
      predictedRecognitionRate = parsePercent(predicted);
    }

    if (country !== ALL_INDICATOR && date === dateFilter) {
      countries.push({
        name: country,
        decisions: bin,
        predicted: parsePercent(predicted),
        rate: parsePercent(rate),
      });
    }
  }

  return {
    decisionsNum,
    recognitionRate,
    predictedRecognitionRate,
    countries,
  };
};

export const calculateRecognitionRatePerYear = (
  data: IRBDataEntry[],
  countryFilter: string = ALL_INDICATOR
): RecognitionRatePerYearFormat[] =>
  data
    .filter(({ country, date }) => country === countryFilter && date)
    .map(({ rate, predicted, date }) => ({
      rate: parsePercent(rate),
      predicted: parsePercent(predicted),
      date,
    }));

export const processRecognitionRatesPerYear = (
  data: IRBDataEntry[]
): RecognitionRatePerYearFormat[] =>
  data.map(({ rate, predicted, date }) => ({
    rate: parsePercent(rate),
    predicted: parsePercent(predicted),
    date,
  }));

export const findRecognitionRateForCountry = (
  data: IRBDataEntry[],
  countryFilter: string = ALL_INDICATOR
): number => {
  const result = data.filter(
    ({ country, date }) => country === countryFilter && !date
  );

  return !data.length ? 0 : parsePercent(result[0].rate);
};

export const findRatesForCountry = (
  data: IRBDataEntry[],
  countryFilter: string = ALL_INDICATOR
): {
  rate: number;
  predicted: number;
} => {
  const result = data.filter(
    ({ country, date }) => country === countryFilter && !date
  );

  return !data.length
    ? {
        rate: 0,
        predicted: 0,
      }
    : {
        rate: parsePercent(result[0].rate),
        predicted: parsePercent(result[0].predicted),
      };
};

export const calculateActualRecognitionRatePerPredictedRecognitionRate = (
  membersData: IRBDataEntry[],
  countryFilter: string = ALL_INDICATOR,
  memberId: string
): ActualRecognitionRatePerPredictedRecognitionRateFormat[] => {
  return membersData
    .filter(
      ({ country, date, name }) =>
        country === countryFilter && date && name !== memberId
    )
    .map(({ rate, predicted, name }) => {
      const { lastName, firstName } = processRPDName(name);

      return {
        rate: parsePercent(rate),
        predicted: parsePercent(predicted),
        memberId: name,
        firstName,
        lastName,
      };
    });
};

export const processIRBCountries = (
  countries: IRBQueryResponseEntry[],
  targetYear: string
): IRBCountry[] => {
  const results: IRBCountry[] = [];
  const list = Array.from(
    new Set([...countries.map(({ country_id }) => country_id)])
  );

  for (const countryName of list) {
    const avgInfo = countries.find(
      ({ country_id, year }) => country_id === countryName && !year
    );
    const yearInfo = countries.find(
      ({ country_id, year }) =>
        country_id === countryName && year === parseInt(targetYear)
    );

    if (avgInfo && yearInfo) {
      results.push({
        name: countryName!,
        decisions: yearInfo.count_min!, // parseInt(yearInfo.count_min!),
        rate: parsePercent(yearInfo.recognition_rate_actual),
        avgDecisions: Math.floor(
          avgInfo.count_min! / IRB_AVAILABLE_YEARS.length // parseBin(avgInfo.count_min!)
        ),
      });
    }
  }
  return results;
};

export const sortCountries = (
  countries: IRBCountry[],
  countrySortOption: CountrySortOption
) => {
  let sortedCountries: IRBCountry[] = [...countries];
  switch (countrySortOption) {
    case "claims": {
      sortedCountries.sort((a, b) => {
        const numA = a.decisions;
        const numB = b.decisions;
        return numA === numB ? a.name.localeCompare(b.name) : numB - numA;
      });
      break;
    }
    case "alphabetical": {
      sortedCountries.sort((a, b) => a.name.localeCompare(b.name));
      break;
    }
    case "recognition": {
      sortedCountries.sort((a, b) => b.rate - a.rate);
      break;
    }
    case "predicted": {
      sortedCountries.sort((a, b) => b.predicted! - a.predicted!);
      break;
    }
  }

  return sortedCountries;
};

export const sortMembers = (
  members: $Member[],
  sortOption: MemberSortOption
) => {
  let sortedMembers: $Member[] = [...members];
  switch (sortOption) {
    case "first":
      sortedMembers.sort((a, b) => a.firstName.localeCompare(b.firstName));
      break;
    case "last":
      sortedMembers.sort((a, b) => a.lastName.localeCompare(b.lastName));
      break;
    case "cases":
      sortedMembers.sort((a, b) => (b.caseCount || 0) - (a.caseCount || 0));
      break;
    case "rate":
      sortedMembers.sort(
        (a, b) => (parsePercent(b.rate!) || 0) - parsePercent(a.rate!) || 0
      );
      break;
    case "predicted":
      sortedMembers.sort(
        (a, b) =>
          (parsePercent(b.predicted!) || 0) - (parsePercent(a.predicted!) || 0)
      );
      break;
  }
  return sortedMembers;
};

export const convertToolTipLabel = (label: string, realm: "fc" | "irb") => {
  if (label === "rate") {
    return "Recognition rate";
  } else if (label === "predicted") {
    return "Predicted recognition rate";
  } else if (label === "date") {
    return "Year";
  } else if (label === "average") {
    return realm === "fc" ? "Overall Rate" : "Average";
  } else if (label === "targetRate") {
    return "Member rate";
  } else if (label === "name") {
    return realm === "irb" ? "Member" : "Judge";
  } else if (label === "jr_rate" || label === "judge_overall_jr_rate") {
    return "Overall JR Rate";
  } else if (label === "leave_rate" || label === "jr_leave_rate") {
    return "Leave Rate";
  } else if (label === "jr_leave_rate") {
    return "Leave Rate";
  } else if (label === "overall_jr_rate") {
    return "Overall JR Rate";
  } else if (label === "year") {
    return "Year";
  } else if (label === "count") {
    return "Total";
  } else if (label === "merits_rate" || label === "judge_merits_rate") {
    return "JR Merits Rate";
  } else if (label === "judge_overall_jr_rate") {
    return "Overall JR Rate";
  } else {
    return label;
  }
};
