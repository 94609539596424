import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import {
  $Member,
  CountrySortOption,
  IRBCountry,
  IRBDataEntry,
  RecognitionRatePerYearFormat,
} from "../../util/interfaces";
import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import {
  Action,
  Actions,
  Alert,
  Banner,
  CaseCountToolTip,
  CountryListOptions,
  Detail,
  Display,
  FilterBox,
  FilterHeader,
  LoadingOverlay,
  Message,
  PageFilterBox,
  RecognitionRateToolTip,
  Section,
  SectionLabel,
  SectionLabelsContainer,
  Stat,
  SubSections,
  Subtitle,
  Title,
} from "../../shared/Common";
import { Clear, Refresh, Search, Tune } from "@mui/icons-material";
import { ALL_INDICATOR, pinnedCommunityStyle } from "../../util/constants";
import {
  calculateRecognitionRatePerYear,
  findRatesForCountry,
  parsePercent,
  processIRBMemberData,
  processIRBMembersData,
  processRPDName,
  reformatIRBEntries,
  reformatIRBYearEntry,
  sortCountries,
} from "../../util/helpers";
import RecognitionRatePerYear from "../graphs/DecisionMaker/IRB/RecognitionRatePerYear";
import StatAvg from "../StatAvg";
import ActualRecognitionRatePerPredictedRecognitionRate from "../graphs/DecisionMaker/IRB/ActualRecognitionRatePerPredictedRecognitionRate";
import HorizontalGraph from "../graphs/HorizontalGraph";
import SectionIndicator from "../SectionIndicator";
import MemberCountries from "../MemberCountries";
import { useDb } from "../../services/UseDb";
import {
  getRpdMember,
  getRpdMemberSummary,
  getRpdMembers,
  getRpdYear,
} from "../../services/db";

const Layer = styled.div`
  display: flex;
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;

const PercentStatBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  height: 220px;
  transition: box-shadow 250ms ease;

  :hover {
    box-shadow: var(--shadow-basic);
  }
`;

const CountStatBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  transition: box-shadow 250ms ease;

  :hover {
    box-shadow: var(--shadow-basic);
  }

  p {
    margin-left: 32px;
  }
`;

const StatTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  label {
    color: var(--light-1);
  }

  @media (min-width: 700px) {
    h2 {
      width: max-content;
    }
  }
`;

const KeyStatsContainer = styled.div`
  display: grid;
  grid-row-gap: 16px;
  margin: 16px 0;

  h2,
  label,
  p {
    color: var(--light-1);
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const RecognitionRatesHeader = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    align-items: flex-start;
  }
`;

const SideNotes = styled.div`
  position: absolute;
  left: 100%;
  margin-left: 48px;
  top: 48px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: var(--shadow-basic);
  width: 212px;

  p {
    margin: 0;
  }

  @media (max-width: 1640px) {
    display: none;
  }
`;

function IRBMember({ duckDbConn }: { duckDbConn: AsyncDuckDBConnection }) {
  const { id } = useParams<{ id: string }>();
  const [memberData, setMemberData] = useState<$Member>({
    firstName: "",
    lastName: "",
    memberId: "",
  });

  const [pinnedCommunity, setPinnedCommunity] = useState(false);

  const [keyDataText, setKeyDataText] = useState("");
  const [decisionsNum, setDecisionsNum] = useState(0);
  const [recognitionRate, setRecognitionRate] = useState(0);
  const [predictedRecognitionRate, setPredictedRecognitionRate] = useState(0);
  const [showPageFilter, setShowPageFilter] = useState(false); // to change when filtering is added

  const [countries, setCountries] = useState<IRBCountry[]>([]);

  // IRB Data
  const [IRBRecognitionRate, setIRBRecognitionRate] = useState(0);
  const [IRBAverageRecognitionRate, setIRBAverageRecognitionRate] = useState(0);

  // Graphs
  const [members, setMembers] = useState<$Member[]>([]);
  const [recognitionRatePerYearData, setRecognitionRatePerYearData] = useState<
    RecognitionRatePerYearFormat[]
  >([]);
  const [
    recognitionRatePerPredictedRateData,
    setRecognitionRatePerPredictedRateData,
  ] = useState<
    {
      rate?: number;
      predicted?: number;
      targetRate?: number;
    }[]
  >();

  // Filters for page
  const [countryPageFilter, setCountryPageFilter] = useState(ALL_INDICATOR);
  const [yearPageFilter, setYearPageFilter] = useState(ALL_INDICATOR);

  // Request data of member and all members
  const [memberRequest, setMemberRequest] = useState<IRBDataEntry[]>([]);
  const [allMembersRequest, setAllMembersRequest] = useState<IRBDataEntry[]>(
    []
  );

  // Loading
  const [loading, setLoading] = useState(false);

  // Country list
  const [filteredCountries, setFilteredCountries] = useState<IRBCountry[]>([]);
  const [countrySearchFilter, setCountrySearchFilter] = useState("");

  const [sortedCountries, setSortedCountries] = useState<IRBCountry[]>([]);
  const [countrySortOption, setCountrySortOption] =
    useState<CountrySortOption>("claims");

  const [countryList, setCountryList] = useState<IRBCountry[]>([]);

  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [minYear, setMinYear] = useState(0);
  const [maxYear, setMaxYear] = useState(0);

  const pinCommunity = () => {
    setPinnedCommunity(!pinnedCommunity);
  };

  const playKeyData = () => {
    const msg = new SpeechSynthesisUtterance();
    msg.text = keyDataText;
    msg.lang = "en";
    window.speechSynthesis.speak(msg);
  };


  const { loading: memberQueryLoading, data: memberQueryRequest } = useDb(
    getRpdMember(duckDbConn, id.includes("'") ? id.replace("'", "''") : id),
    [id]
  );


  const { loading: memberSummaryLoading, data: memberSummaryRequest } = useDb(
    getRpdMemberSummary(
      duckDbConn,
      id.includes("'") ? id.replace("'", "''") : id,
      countryPageFilter,
      parseInt(yearPageFilter)
    ),
    [id, countryPageFilter, parseInt(yearPageFilter)]
  );


  const { loading: allMembersQueryLoading, data: allMembersQueryRequest } =
    useDb(
      getRpdMembers(
        duckDbConn,
        countryPageFilter,
        20,
        parseInt(yearPageFilter)
      ),
      [countryPageFilter, parseInt(yearPageFilter)]
    );


  const { loading: yearCountryLoading, data: yearCountryData } = useDb(
    getRpdYear(duckDbConn, countryPageFilter, parseInt(yearPageFilter)),
    [countryPageFilter, yearPageFilter]
  );
  // end of queries

  useEffect(() => {
    setLoading(
      memberQueryLoading ||
        allMembersQueryLoading ||
        yearCountryLoading ||
        memberSummaryLoading
    );
  }, [
    memberQueryLoading,
    allMembersQueryLoading,
    yearCountryLoading,
    memberSummaryLoading,
  ]);

  useEffect(() => {
    if (memberSummaryRequest) {
      const { count_min, recognition_rate_actual, recognition_rate_predicted } =
        memberSummaryRequest;

      setDecisionsNum(count_min);
      setRecognitionRate(parsePercent(recognition_rate_actual));
      setPredictedRecognitionRate(parsePercent(recognition_rate_predicted));
    }
  }, [memberSummaryRequest]);

  useEffect(() => {
    setCountryPageFilter(ALL_INDICATOR);
  }, [yearPageFilter]);

  useEffect(() => {
    if (memberQueryRequest && allMembersQueryRequest && yearCountryData) {
      const allMembersData = reformatIRBEntries([...allMembersQueryRequest]);
      const allMembersFiltered = (allMembersData as IRBDataEntry[]).filter(
        ({ name }) => name !== id
      );

      const { recognition_rate_actual, recognition_rate_predicted } =
        yearCountryData[0];

      setIRBRecognitionRate(parsePercent(recognition_rate_actual));
      setIRBAverageRecognitionRate(parsePercent(recognition_rate_predicted));

      setAllMembersRequest(allMembersFiltered);

      setMemberRequest(reformatIRBEntries([...memberQueryRequest]));
    }
  }, [memberQueryRequest, allMembersQueryRequest, id, yearCountryData]);

  // useEffect(() => {
  //   setKeyDataText(
  //     `Judge ${memberData.firstName} ${memberData.lastName} has the following key statistics, an approximate of ${decisionsNum} refugee decisions, a refugee recognition rate of ${recognitionRate}% compared to a predicted average of ${predictedRecognitionRate}% based on yearly country of origin averages`
  //   );
  // }, [memberData, decisionsNum, recognitionRate, predictedRecognitionRate]);

  useEffect(() => {
    const memberInfo = processRPDName(id);
    setMemberData(memberInfo);
  }, [id]);

  useEffect(() => {
    const yearsList: number[] = Array.from(
      new Set(
        memberRequest
          .filter(({ date }) => date !== null)
          .map(({ date }) => date)
      )
    );

    setAvailableYears(yearsList);
    setMaxYear(Math.max(...yearsList));
    setMinYear(Math.min(...yearsList));

    const { countries } = processIRBMemberData(memberRequest, yearPageFilter);

    setCountries(
      countries
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(({ decisions }) => decisions > 20)
    );
    setRecognitionRatePerYearData([
      ...calculateRecognitionRatePerYear(
        memberRequest.sort((a, b) => a.date - b.date)
      ),
    ]);
  }, [memberRequest, id, yearPageFilter]);

  // Retrieve data for all other members
  useEffect(() => {
    const otherMembers = processIRBMembersData(allMembersRequest);
    setMembers(otherMembers);
    const ratePredicted = otherMembers.map(
      ({ rate, predicted, firstName, lastName, memberId }) => ({
        rate: rate!,
        predicted: predicted!,
        name: `${firstName} ${lastName}`,
        id: memberId,
      })
    );
    setRecognitionRatePerPredictedRateData([
      ...ratePredicted,
      {
        predicted: predictedRecognitionRate,
        targetRate: recognitionRate,
      },
    ]);
  }, [allMembersRequest, predictedRecognitionRate, recognitionRate]);

  useEffect(() => {
    setRecognitionRatePerYearData(
      calculateRecognitionRatePerYear(memberRequest, countryPageFilter)
    );
  }, [countryPageFilter, memberRequest, id]);

  // Country list logic for sorting and filtering
  useEffect(() => {
    const sortedCountries = sortCountries([...countries], countrySortOption);
    setSortedCountries(sortedCountries);
  }, [countrySortOption, countries]);

  useEffect(() => {
    let searchResults = [...countries];

    searchResults = countries.filter(({ name }) =>
      name.toUpperCase().includes(countrySearchFilter.toUpperCase())
    );
    setFilteredCountries(searchResults);
  }, [countrySearchFilter, countries]);

  useEffect(() => {
    setCountryList(countrySearchFilter ? filteredCountries : sortedCountries);
  }, [sortedCountries, filteredCountries, countrySearchFilter]);

  return (
    <Layer>
      <SectionLabelsContainer>
        <Detail>
          {memberData.firstName} {memberData.lastName}
          <br />
          <span style={{ color: "var(--dark-2)" }}>
            Immigration Refugee Board Member
          </span>
        </Detail>
        <SectionIndicator start="key-data" />
        <SectionLabel href="#key-data" indented label="Key Data" />
        <SubSections>
          <Detail>Number of Decisions</Detail>
          <Detail>Recognition Rate</Detail>
        </SubSections>
        <SectionLabel
          href="#recognition-rates"
          indented
          label="Recognition Rates"
        />
        <SectionLabel
          href="#top-countries"
          indented
          label="Countries of Origin"
        />
        {/* <SectionLabel href="#community" indented label="Community" /> */}
      </SectionLabelsContainer>
      <Container>
        <Section id="biography">
          <Display>{`${memberData.firstName} ${memberData.lastName}`}</Display>
          <h2 style={{ marginTop: 0 }}>
            Member of the Immigration Refugee Board
          </h2>
          {memberData.bio && (
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam
              fringilla lorem urna malesuada velit, sapien morbi. Sed accumsan,
              enim ipsum, enim. Ligula pellentesque id et massa sodales viverra
              tincidunt vel. Eu, sed id bibendum imperdiet condimentum interdum
              risus. Commodo metus neque, habitant commodo, sed cum facilisis.
              Turpis urna cras bibendum adipiscing quis vel nisl, turpis.
            </p>
          )}
        </Section>
        <hr />
        {decisionsNum < 20 && (
          <Banner style={{ margin: "64px 0" }}>
            <BannerContent>
              <Alert style={{ marginBottom: "8px" }}>
                There is a small number of decisions under this decision maker
                in our data
              </Alert>
              <Subtitle>
                The data below does not provide a complete insight into their
                decision process due to the small sample size.
              </Subtitle>
            </BannerContent>
          </Banner>
        )}
        <div style={{ position: "relative" }}>
          {showPageFilter && (
            <PageFilterBox>
              <Detail style={{ marginRight: "12px" }}>Filter by</Detail>
              <FilterBox>
                <Detail>year</Detail>
                <Select
                  size="small"
                  onChange={(e) => setYearPageFilter(e.target.value)}
                  value={yearPageFilter}
                >
                  <MenuItem value={ALL_INDICATOR}>
                    <Action>
                      All ({minYear} to {maxYear})
                    </Action>
                  </MenuItem>
                  {availableYears
                    .sort()
                    .reverse()
                    .map((year) => (
                      <MenuItem key={`y-${year}`} value={year}>
                        <Action>{year}</Action>
                      </MenuItem>
                    ))}
                </Select>
              </FilterBox>
              <FilterBox style={{ marginLeft: "16px" }}>
                <Detail>country</Detail>
                <Select
                  size="small"
                  onChange={(e) => setCountryPageFilter(e.target.value)}
                  value={countryPageFilter}
                >
                  <MenuItem value={ALL_INDICATOR}>
                    <Action>All</Action>
                  </MenuItem>
                  {countries.map(({ name }) => (
                    <MenuItem key={`item-${name}`} value={name}>
                      <Action>{name}</Action>
                    </MenuItem>
                  ))}
                </Select>
                <IconButton
                  onClick={() => {
                    setYearPageFilter(ALL_INDICATOR);
                    setCountryPageFilter(ALL_INDICATOR);
                  }}
                  disabled={
                    yearPageFilter === "+ALL" && countryPageFilter === "+ALL"
                  }
                  style={{ marginLeft: "8px" }}
                >
                  <Refresh fontSize="small" />
                </IconButton>
              </FilterBox>
            </PageFilterBox>
          )}
          {!showPageFilter && (
            <PageFilterBox
              style={{
                padding: "8px",
              }}
            >
              <Button onClick={() => setShowPageFilter(true)}>
                <Action style={{ marginRight: "8px" }}>
                  Filter by year and country
                </Action>
                <Tune fontSize="small" />
              </Button>
            </PageFilterBox>
          )}
          <Section id="key-data">
            {/* <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <IconButton size="small" onClick={() => playKeyData()}>
              <Hearing color="primary" />
            </IconButton>
          </div> */}
            <Display>Key Data</Display>
            <Title>
              from{" "}
              {yearPageFilter === ALL_INDICATOR ? (
                <>
                  <b>{minYear}</b> to <b>{maxYear}</b>
                </>
              ) : (
                <b>{yearPageFilter}</b>
              )}{" "}
              for{" "}
              <b>
                {countryPageFilter === ALL_INDICATOR
                  ? "all countries"
                  : countryPageFilter}
              </b>
            </Title>
            <KeyStatsContainer>
              <CountStatBox style={{ backgroundColor: "#2D7299" }}>
                <StatTitleContainer>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h2>Decisions made</h2>
                    <CaseCountToolTip color="light" />
                  </div>
                </StatTitleContainer>
                <Stat style={{ textAlign: "end" }}>~{decisionsNum}</Stat>
              </CountStatBox>
              <PercentStatBox style={{ backgroundColor: "#AC346D" }}>
                <StatTitleContainer>
                  <h2>Recognition Rate</h2>
                  <RecognitionRateToolTip color="light" />
                </StatTitleContainer>
                <StatAvg
                  stat={recognitionRate}
                  avg={predictedRecognitionRate}
                />
              </PercentStatBox>
            </KeyStatsContainer>
            <Actions>
              <Subtitle>
                *predicted based on yearly country of origin averages
              </Subtitle>
            </Actions>
          </Section>
          <Section id="recognition-rates">
            <FilterHeader>
              <RecognitionRatesHeader>
                <Display>Recognition Rates</Display>
                <RecognitionRateToolTip size="medium" color="dark" />
              </RecognitionRatesHeader>
            </FilterHeader>
            <div style={{ position: "relative" }}>
              <HorizontalGraph
                memberRate={recognitionRate}
                average={IRBAverageRecognitionRate}
                members={members}
                memberName={`${memberData.firstName} ${memberData.lastName}`}
              />
              <SideNotes>
                <p>
                  The recognition rate of {memberData.firstName}{" "}
                  {memberData.lastName} is{" "}
                  <b>
                    {recognitionRate > IRBAverageRecognitionRate
                      ? "above"
                      : "below"}
                  </b>{" "}
                  the IRB average for{" "}
                  {countryPageFilter === ALL_INDICATOR
                    ? "the combined rate of all countries"
                    : countryPageFilter}
                </p>
              </SideNotes>
            </div>
            <div>
              <h2>Recognition Rate per Year</h2>
              <RecognitionRatePerYear data={recognitionRatePerYearData} />
            </div>
            <div>
              <h2>Actual Recognition Rate per Predicted Recognition Rate</h2>
              <ActualRecognitionRatePerPredictedRecognitionRate
                memberName={`${memberData.firstName} ${memberData.lastName}`}
                data={[
                  ...(recognitionRatePerPredictedRateData || []),
                  {
                    predicted: IRBAverageRecognitionRate,
                    average: IRBRecognitionRate,
                  },
                ]}
                showAverage
              />
            </div>
          </Section>
          <Section id="top-countries">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Display>Countries of Origin</Display>
              <Subtitle style={{ marginLeft: "8px" }}>(20+ decisions)</Subtitle>
            </div>
            <CountryListOptions>
              <TextField
                size="small"
                variant="outlined"
                label="Search by name"
                style={{ width: "100%" }}
                value={countrySearchFilter}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCountrySearchFilter(event.target.value)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {countrySearchFilter !== "" ? (
                        <IconButton
                          size="small"
                          aria-label="search"
                          onClick={() => setCountrySearchFilter("")}
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      ) : (
                        <Search fontSize="small" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Message
                text={`${countryList.length} ${
                  countryList.length === 1 ? "result" : "results"
                }`}
                type="subtitle"
              />
              <Actions>
                <FilterBox>
                  <Detail>Sort by</Detail>
                  <Select
                    size="small"
                    onChange={(e) =>
                      setCountrySortOption(e.target.value as CountrySortOption)
                    }
                    value={countrySortOption}
                    disabled={countrySearchFilter !== ""}
                  >
                    <MenuItem value={"claims"}>
                      <Action>Number of claims</Action>
                    </MenuItem>
                    <MenuItem value={"alphabetical"}>
                      <Action>Alphabetical</Action>
                    </MenuItem>
                    <MenuItem value={"recognition"}>
                      <Action>Recognition rate</Action>
                    </MenuItem>
                    <MenuItem value={"predicted"}>
                      <Action>Predicted rate</Action>
                    </MenuItem>
                  </Select>
                </FilterBox>
              </Actions>
            </CountryListOptions>
            <MemberCountries
              countries={countryList}
              countryFilter={ALL_INDICATOR}
              setCountryFilter={() => {}}
            />
          </Section>
        </div>
      </Container>
    </Layer>
  );
}

export default IRBMember;
