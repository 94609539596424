import { ErrorOutline, Info, InfoOutlined } from "@mui/icons-material";
import styled from "styled-components";
import { IconButton, Tooltip } from "@mui/material";
import loadingIcon from "../assets/loading-color.svg";
import { convertToolTipLabel } from "../util/helpers";

export const Title = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
`;

export const Alert = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`;

export const Detail = styled.label`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
`;

export const Action = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-decoration-line: underline;
  margin: 0;
`;

export const Display = styled.h1`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  margin-bottom: 0;

  @media (max-width: 700px) {
    font-size: 40px;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  scroll-margin: 84px;

  @media (min-width: 600px) {
    padding: 0 64px;
  }
`;

export const SectionLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 148px;
  left: 16px;
  max-width: 120px;
  height: min-content;
  grid-row-gap: 12px;

  div label {
    font-weight: normal;
  }

  @media (max-width: 1380px) {
    display: none;
  }

  @media (min-width: 1640px) {
    left: 80px;
    max-width: 152px;
  }
`;

export const SectionLabel = ({
  label,
  indented = false,
  active = true,
  href,
}: {
  label: string;
  indented?: boolean;
  active?: boolean;
  href: string;
}) => {
  const indentSize = indented ? 8 : 0;
  const activeColor = active ? "var(--dark-1)" : "var(--dark-2)";
  const border = indented ? `border-left: solid ${activeColor} 2px;` : "";

  const Box = styled.div`
    ${border}
    display: flex;
    padding-left: ${indentSize}px;

    p {
      color: ${activeColor};
    }
  `;

  return (
    <Box>
      <a href={href}>
        <Action>{label}</Action>
      </a>
    </Box>
  );
};

const BannerContainer = styled.div`
  display: flex;
  box-shadow: var(--shadow-basic);
  background-color: var(--yellow);
  border-radius: 4px;
  padding: 16px 24px;
`;

export const Banner = ({
  type = "alert",
  style,
  children,
}: {
  type?: "alert" | "info";
  style?: React.CSSProperties;
  children: React.ReactChild;
}) => (
  <BannerContainer style={{ ...style }}>
    <div style={{ marginRight: "8px" }}>
      {type === "alert" ? <ErrorOutline /> : <InfoOutlined />}
    </div>
    {children}
  </BannerContainer>
);

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const GraphContainer = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  min-height: 600px;
  height: 600px;
  position: relative;

  @media (max-width: 700px) {
    height: 84px;
    min-height: 84px;
  }
`;

const MobileGraphsOverlay = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--light-1);

  p {
    text-align: center;
    margin-top: 12px;
  }

  @media (min-width: 700px) {
    display: none;
  }
`;

export const MobileGraphsBanner = () => (
  <MobileGraphsOverlay>
    <InfoOutlined />
    <Subtitle>
      Graphs are not yet available on mobile devices. Please view on a desktop
      computer.
    </Subtitle>
  </MobileGraphsOverlay>
);

export const FilterBox = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 8px;
  }

  @media (max-width: 700px) {
    width: 100%;
    justify-content: flex-end;
  }
`;

export const Stat = styled.p`
  font-family: DM Sans;
  font-weight: bold;
  font-size: 48px;

  @media (max-width: 700px) {
    font-size: 40px;
  }
`;

export const EmptyResults = ({ message }: { message: string }) => (
  <Actions style={{ justifyContent: "center", margin: "64px 0px" }}>
    <Subtitle>{message}</Subtitle>
  </Actions>
);

const ToolTipText = styled.div`
  display: grid;
  grid-row-gap: 8px;
  padding: 8px;

  button {
    height: max-content;
  }

  p {
    color: var(--light-1);
    margin: 0;
    font-family: "DM Sans";
    font-size: 14px;
  }

  a,
  a:visited {
    color: var(--yellow);
  }
`;

export const CaseCountToolTip = ({
  color,
  size = "small",
}: {
  color: "light" | "dark";
  size?: "small" | "medium" | "large";
}) => (
  <Tooltip
    title={
      <ToolTipText>
        <p>
          Decision numbers are shown in ranges of 20.{" "}
          <a href="/glossary#decisions" target="_blank">
            Learn more
          </a>
        </p>
      </ToolTipText>
    }
  >
    <IconButton size={size}>
      <Info color={color === "light" ? "secondary" : "primary"} />
    </IconButton>
  </Tooltip>
);

export const RecognitionRateToolTip = ({
  color,
  size = "small",
}: {
  color: "light" | "dark";
  size?: "small" | "medium" | "large";
}) => (
  <Tooltip
    title={
      <ToolTipText>
        <p>
          Predicted recognition rates are calculated based on yearly country of
          origin averages for the cases heard by decision-makers.{" "}
          <a href="/glossary#recognition-rate" target="_blank">
            Learn more
          </a>
        </p>
      </ToolTipText>
    }
  >
    <IconButton size={size}>
      <Info color={color === "light" ? "secondary" : "primary"} />
    </IconButton>
  </Tooltip>
);

const OverlayBox = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--light-1);
  z-index: 100;
  pointer-events: all;
  backdrop-filter: blur(4px);

  img {
    margin: -32px;
    height: 120px;
    width: 120px;
  }

  hr {
    width: 75%;
  }

  p {
    color: var(--dark-1);
    margin-top: 0;
  }
`;

export const LoadingOverlay = () => (
  <OverlayBox>
    <img alt="loading" src={loadingIcon} />
    <Subtitle style={{ marginTop: "48px" }}>loading data</Subtitle>
    <hr />
    <Subtitle>Refugee Law Lab Portal</Subtitle>
  </OverlayBox>
);

const MessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 100%;
  background-color: var(--grey-1);
  height: 100%;

  p {
    margin: 0;
  }
`;

export const Message = ({
  text,
  type,
}: {
  text: string;
  type: "title" | "subtitle" | "text";
}) => (
  <MessageBox>
    {type === "title" && <Title>{text}</Title>}
    {type === "subtitle" && <Subtitle>{text}</Subtitle>}
    {type === "text" && <p>{text}</p>}
  </MessageBox>
);

export const MobileOnly = styled.div`
  @media (min-width: 701px) {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
`;

export const SubSections = styled.div`
  display: grid;
  padding-left: 8px;
  grid-row-gap: 6px;
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const CountriesContainer = styled.div`
  display: grid;
  grid-row-gap: 16px;
  margin: 32px 0;
`;

export const CountryListOptions = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 16px;
  margin-top: 16px;
  align-items: center;
  height: 40px;

  input,
  label {
    font-family: DM Sans;
  }

  div {
    height: 40px;
  }

  @media (max-width: 700px) {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    height: min-content;

    div:nth-of-type(1) {
      grid-column: span 3;
    }

    div:nth-of-type(2) {
      grid-column: auto / span 1;
    }

    div:nth-of-type(3) {
      grid-column: auto / span 2;
    }
  }
`;

export const CountryLine = styled.div`
  top: 0;
  opacity: 0;
  left: 0px;
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: var(--dark-1);
  transition: transform 250ms ease, opacity 250ms ease;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const NoData = () => (
  <div
    style={{ display: "flex", justifyContent: "center", padding: "124px 0" }}
  >
    <ErrorOutline />
    <Subtitle style={{ marginLeft: "8px" }}>
      No data for members with at least 20 decisions made
    </Subtitle>
  </div>
);

export const TooltipBox = styled.div`
  padding: 12px 16px;
  background-color: var(--light-1);
  border: solid 1px var(--dark-1);
`;

const TooltipLabel = styled.div`
  display: flex;
  align-items: center;
`;

const LinkBox = styled.a`
  pointer-events: all;
  position: absolute;
  left: -32px;
  top: -32px;
  height: calc(100% + 64px);
  width: calc(100% + 64px);
  cursor: pointer;
`;

export const ActualRecognitionRatePerPredictedRecognitionRateTooltip = ({
  active,
  payload,
  units,
}: {
  active?: any;
  payload?: any;
  units: any;
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const label = payload[0].name;
    const id = data.id;
    const color = payload[0].color;
    const chartKeys = Object.keys(data).filter((key) => key !== "id");

    if (Object.hasOwn(data, "line")) {
      return null;
    }

    return (
      <TooltipBox>
        {Object.hasOwn(data, "average") ||
        Object.hasOwn(data, "targetRate") ? null : (
          <LinkBox
            target="_blank"
            href={`/immigration-refugee-board/member/${id}`}
          ></LinkBox>
        )}
        {chartKeys.map((key, index) => (
          <div key={`${key}-${index}`}>
            <Subtitle>{convertToolTipLabel(key, "irb")}</Subtitle>
            <Title
              style={{
                marginBottom: index === chartKeys.length - 1 ? "0px" : "8px",
              }}
            >
              {data[key]} {units[key] || ""}
            </Title>
          </div>
        ))}
        {payload.length === 1 && (
          <>
            <hr />
            <TooltipLabel>
              <div
                style={{
                  backgroundColor: color,
                  height: "12px",
                  width: "12px",
                  borderRadius: "100%",
                  marginRight: "4px",
                }}
              />
              <Detail>{label}</Detail>
            </TooltipLabel>
          </>
        )}
      </TooltipBox>
    );
  }

  return null;
};

export const CustomTooltip = ({
  active,
  payload,
  units,
}: {
  active?: any;
  payload?: any;
  units: any;
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const label = payload[0].name;
    const color = payload[0].color;
    const chartKeys = Object.keys(data);

    return (
      <TooltipBox>
        {chartKeys.map((key, index) => (
          <div key={`${key}-${index}`}>
            <Subtitle>{convertToolTipLabel(key, "irb")}</Subtitle>
            <Title
              style={{
                marginBottom: index === chartKeys.length - 1 ? "0px" : "8px",
              }}
            >
              {typeof data[key] === "number" && key !== "year" && key !== "date"
                ? data[key].toLocaleString()  // Directly use .toLocaleString()
                : data[key]}
              {units[key] || ""}
            </Title>
          </div>
        ))}
        {payload.length === 1 && (
          <>
            <hr />
            <TooltipLabel>
              <div
                style={{
                  backgroundColor: color,
                  height: "12px",
                  width: "12px",
                  borderRadius: "100%",
                  marginRight: "4px",
                }}
              />
              <Detail>{label}</Detail>
            </TooltipLabel>
          </>
        )}
      </TooltipBox>
    );
  }

  return null;
};

export const PageFilterBox = styled.div`
  width: max-content;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  margin-left: auto;
  margin-top: 16px;
  margin-bottom: -48px;
  position: sticky;
  top: 80px;
  right: 0px;
  z-index: 10;
  background-color: var(--light-1);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-end;

    label {
      margin-bottom: 4px;
    }

    grid-gap: 8px;
  }
`;
